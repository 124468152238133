<template>
  <v-modal
    v-model="componentValue"
    title="Оценка консультации"
    size="xxl"
    @input="onClose"
    class="nps-options-modal"
  >
    <div class="nps-options-modal_content">
      <VTabs
        v-model="localSelectedTab"
        :options="options"
        size="m"
        class="nps-v-tabs"
        variant="white-shadow"
      />
      <Preloader v-if="isLoading" />
      <template v-else>
        <EvaluationsInfo
          v-if="localSelectedTab.value === 'evaluationsModalInfo'"
          :evaluation-object="evaluationObject"
          :report-object="reportObject"
          class="nps-options-modal_content"
        />
        <EvaluationsCheckList
          v-else
          :doctor-info="doctorInfo"
          :consultation-id="evaluationObject.id"
        />
      </template>
    </div>
  </v-modal>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import { VModal, VTabs } from '@doctis.front/doctis.designsystem';
import { mapActions } from 'vuex';
import { formatConsultationDate, fullNameFio } from '@/helpers/index.js';
import { FETCH_EVALUATION_MONITORING_EVENT } from '@/store/types';
import { EvaluationsInfo, EvaluationsCheckList } from './Tabs/index.js';

export default {
  components: {
    VModal,
    VTabs,
    Preloader,
    EvaluationsInfo,
    EvaluationsCheckList,
  },
  props: {
    evaluationObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSelectedTab: { label: 'Общая информация', value: 'evaluationsModalInfo' },
      options: [
        { label: 'Общая информация', value: 'evaluationsModalInfo' },
        { label: 'Чек-лист', value: 'evaluationsModalChecklist' },
      ],
      reportObject: null,
      isLoading: true,
      doctorInfo: [
        {
          label: 'ФИО врача',
          content: this.fullNameFio(
            this.evaluationObject.doctorSpecialization?.doctor?.lastName,
            this.evaluationObject.doctorSpecialization?.doctor?.firstName,
            this.evaluationObject.doctorSpecialization?.doctor?.middleName,
          ),
        },
        {
          label: 'Дата закрытия',
          content: this.formatConsultationDate(this.evaluationObject?.closeDate),
        },
        {
          label: 'Итого баллов',
          content: String(this.evaluationObject?.evaluationScore),
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit('Evaluations/SET_EVALUATION_MONITORING_ID', null);
    const id = this.evaluationObject.remoteMonitoringReasonEventId;
    if (id) {
      try {
        const res = await this.monitoringEvent(id);
        this.reportObject = res;
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    }
    this.isLoading = false;
  },
  computed: {
    componentValue: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          this.onClose();
        }
      },
    },
  },
  methods: {
    ...mapActions({
      monitoringEvent: FETCH_EVALUATION_MONITORING_EVENT,
    }),
    fullNameFio,
    formatConsultationDate,
    onClose() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss">
  .nps-options-modal {
    &_content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .relative {
      width: 100%;
      max-width: 1200px;
    }
    p {
      margin-bottom: 0;
    }
    .evaluations-block {
      width: 50%;
    }
  }
  .nps-v-tabs {
    width: 100%;
    div {
      width: 50%;
    }
  }
</style>
