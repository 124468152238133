<template>
  <div>
    <Preloader v-if="isLoading" />
    <div v-else class="evaluation-check-list">
      <EvaluationsCheckListInfo
        title="Таблица показателей"
        :doctor-info="doctorInfo"
      />
      <p class="evaluation-check-list_attention">
        Для завершения оценки убедитесь,
        что вы отметили “Да” или “Нет”
        в каждой строке таблицы и написали комментарий.
        Если комментарий не требуется, напишите “Комментарий не требуется”
      </p>
      <EvaluationsCheckListTable
        v-if="checkListArray.length"
        :is-disabled="isAllDone"
        :check-list-object="checkListArray"
        @update:checkedList="updatedCheckedList"
      />
      <EvaluationsModalComment
        title="Комментарий от эксперта"
        :is-disabled="isDisabledComment"
        :check-list-comment="checkListCommentObject?.comment"
        @updated:comment="updatedExpertComment"
      />
      <EvaluationsCheckListButtons
        :is-disabled="isDoneButtonDisabled"
        :is-saving-disabled="isSavingDisabled"
        :is-all-done="isAllDone"
        :is-comment="!!checkListCommentObject?.comment"
        @checkListDone="checkListDone"
        @checkListSave="checkListSave"
        @downloadReport="downloadReport"
      />
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  POST_EVALUATION_CHECK_LIST_COMMENT,
  FETCH_EVALUATION_CHECK_LIST,
  FETCH_EVALUATION_CHECK_LIST_COMMENT,
  FETCH_EVALUATION_SAVED_CHECK_LIST,
  POST_EVALUATION_CHECK_LIST,
  GET_EVALUATION_MONITORING_ID,
  FETCH_EVALUATION_MONITORING_ID,
  FETCH_EVALUATION_REPORT,
} from '@/store/types';
import {
  EvaluationsCheckListInfo,
  EvaluationsCheckListTable,
  EvaluationsModalComment,
  EvaluationsCheckListButtons,
} from '../Parts/index.js';

export default {
  props: {
    doctorInfo: {
      type: Array,
      default: () => [],
    },
    consultationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isDoneButtonDisabled: true,
      // кнопка сохранить заблокирована если не один чекбокс не выбран
      isSavingDisabled: true,
      isDisabledComment: true,
      isAllDone: false,
      checkListArray: [],
      checkListCommentObject: null,
      isLoading: true,
      localUpdatedCheckedList: null,
      expertComment: '',
      monitoringTemplateId: null,
    };
  },
  components: {
    Preloader,
    EvaluationsCheckListInfo,
    EvaluationsCheckListTable,
    EvaluationsModalComment,
    EvaluationsCheckListButtons,
  },
  async mounted() {
    this.monitoringTemplateId = null;
    if (this.monitoringId) {
      const { newMonitoringTemplateId } = await this.getMonitoringById(this.monitoringId);
      this.monitoringTemplateId = newMonitoringTemplateId;
    }
    try {
      const checkList = await this.checkList(this.monitoringTemplateId);
      this.checkListArray = checkList.data;
      const savedCheckList = await this.savedCheckList(this.consultationId);
      if (savedCheckList.data && savedCheckList.data.length > 0) {
        // если есть сохраненные данные, то перебираем checkListArray и находим соответствующий элемент savedCheckList, если он есть, то обновляем value, если нет, то оставляем как есть
        this.checkListArray = this.checkListArray.map((checkListItem) => {
          const savedItem = savedCheckList.data.find(
            (saved) => saved.evaluation_checkbox_id === checkListItem.id,
          );
          return savedItem && savedItem.value !== null && savedItem.value !== undefined ? {
            ...checkListItem,
            value: savedItem ? savedItem.value : undefined,
          } : checkListItem;
        });
        const isCheckListChecked = this.checkListArray.some((item) => item.value !== undefined);
        if (isCheckListChecked) {
          this.isSavingDisabled = false;
        }
        const allChecked = this.checkListArray.every((item) => item.value !== undefined);
        if (allChecked) {
          this.isDisabledComment = false;
          this.isSavingDisabled = true;
        }
      }
    } catch (error) {
      console.warn(error);
    }

    // получаем коммент опроса
    const commentData = await this.checkListComment(this.consultationId);
    if (commentData.data) {
      this.checkListCommentObject = commentData.data;
      this.expertComment = this.checkListCommentObject?.comment;
      if (this.expertComment) {
        this.isAllDone = true;
        this.isDisabledComment = true;
      }
    }
    this.isLoading = false;
  },
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters({
      monitoringId: GET_EVALUATION_MONITORING_ID,
    }),
  },
  methods: {
    ...mapActions({
      sendComment: POST_EVALUATION_CHECK_LIST_COMMENT,
      checkList: FETCH_EVALUATION_CHECK_LIST,
      checkListComment: FETCH_EVALUATION_CHECK_LIST_COMMENT,
      getMonitoringById: FETCH_EVALUATION_MONITORING_ID,
      savedCheckList: FETCH_EVALUATION_SAVED_CHECK_LIST,
      sendCheckList: POST_EVALUATION_CHECK_LIST,
      getReport: FETCH_EVALUATION_REPORT,
    }),
    updatedCheckedList(updatedCheckedList) {
      // добавляем локальный список то что надо для отправки post в item только id вопроса и значение true или false
      this.localUpdatedCheckedList = updatedCheckedList.map((item) => ({
        ...item,
      }));
      // Определяем состояние кнопки сохранить
      this.isSavingDisabled = !this.localUpdatedCheckedList.length > 0;
      // убираем disabled поля комментария, вычисляем сколько осталось чекбоксов не сохранных
      const savedItemsCount = this.checkListArray.filter((item) => item.value !== undefined).length;
      const totalCheckedItemsCount = savedItemsCount + updatedCheckedList.length;
      const remainingItemsToCheck = this.checkListArray.length - totalCheckedItemsCount;
      if (remainingItemsToCheck === 0) {
        this.isDisabledComment = false;
      }
    },
    // обновление комментария
    updatedExpertComment(comment) {
      this.expertComment = comment;
      this.isSavingDisabled = true;
      this.isDoneButtonDisabled = !comment;
    },
    checkListDone() {
      // сохранение комментария
      this.sendComment({
        consultation_id: this.consultationId,
        comment: this.expertComment,
      });
      // сохранение чеклиста
      if (this.localUpdatedCheckedList.length > 0) {
        this.sendCheckList({
          consultation_id: this.consultationId,
          result: this.localUpdatedCheckedList,
        });
      }
      // дизэйбл кнопок и radio при завершении оценки
      this.isDisabledComment = true;
      this.isAllDone = true;
    },
    checkListSave() {
      // сохранение чеклиста
      if (this.localUpdatedCheckedList.length > 0) {
        this.sendCheckList({
          consultation_id: this.consultationId,
          result: this.localUpdatedCheckedList,
        });
      }
    },
    downloadReport() {
      this.getReport(this.consultationId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .evaluation-check-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_attention {
      background: #eaf3ff;
      padding: 16px;
      font-size: 14px;
      line-height: 16px;
      color: #454B59;
    }
  }
</style>
