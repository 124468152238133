// формат месяца без точки
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

const customRuLocale = {
  ...ru,
  localize: {
    ...ru.localize,
    month: (n, { width }) => {
      const monthsShort = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
      if (width === 'abbreviated') return monthsShort[n];
      return ru.localize.month(n, { width });
    },
  },
};

export default function formatConsultationDate(rawDate, formatOption = 'd MMM HH:mm', customLocale = customRuLocale) {
  if (!rawDate) return '';
  return format(parseISO(rawDate), formatOption, { locale: customLocale });
}
