<template>
  <div class="evaluations-modal-comment">
    <div v-if="title" class="evaluations-modal-comment_title">
      {{ title }}
    </div>
    <VAttribute
      v-if="isCallCenter"
      :content="comment"
      size="m"
      class="evaluations-modal-comment_attr"
    />
    <VTextArea
      v-else
      v-model="commentExpert"
      :placeholder="placeholder"
      :disabled="isDisabled"
      size="s"
      @input="$emit('updated:comment', commentExpert)"
    />
  </div>
</template>

<script>
import { VAttribute, VTextArea } from '@doctis.front/doctis.designsystem';

export default {
  name: 'EvaluationsModalComment',
  props: {
    title: {
      type: String,
      default: '',
    },
    isCallCenter: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    commentCallCenter: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Напишите комментарий от врача',
    },
    checkListComment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      commentExpert: this.checkListComment,
    };
  },
  components: {
    VAttribute,
    VTextArea,
  },
  computed: {
    comment() {
      return this.commentCallCenter ? this.commentCallCenter : 'Комментарий отсутствует';
    },
  },
};
</script>

<style lang="scss">
  .evaluations-modal-comment {
    display: flex;
    gap: 8px;
    flex-direction: column;
    &_title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #161E2F;
    }
    &_attr {
      justify-content: flex-start;
      span {
        font-size: 16px;
        line-height: 20px;
        color: #7F838D;
      }
    }
  }
</style>
