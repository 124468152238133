var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.reportObject)?_c('EvaluationsMonitoringBlock',{attrs:{"monitoring-object":_vm.reportObject,"protocol-tag-title":_vm.evaluationObject.protocol?.protocolTag?.title}}):_vm._e(),_c('div',{staticClass:"d-flex gap-4"},[_c('EvaluationsCardBlock',{attrs:{"title":"Пациент","attr-right-icon":"copy","is-block-row":"","copy-content":String(_vm.evaluationObject.patientId),"fio":_vm.fullNameFio(
        _vm.evaluationObject.patient?.lastName,
        _vm.evaluationObject.patient?.firstName,
        _vm.evaluationObject.patient?.middleName),"phone-number":_vm.evaluationObject.patient?.phoneNumber,"diagnosis-title":"Диагноз","diagnosis":_vm.evaluationObject?.body}}),_c('EvaluationsCardBlock',{attrs:{"title":"Врач","attr-left-icon":"doctor","is-block-row":"","attr-right-icon":"copy","copy-content":String(_vm.evaluationObject.doctorSpecialization?.doctor?.id),"attr-content":_vm.doctorSpecialization,"fio":_vm.fullNameFio(
        _vm.evaluationObject.doctorSpecialization?.doctor?.lastName,
        _vm.evaluationObject.doctorSpecialization?.doctor?.firstName,
        _vm.evaluationObject.doctorSpecialization?.doctor?.middleName
      ),"phone-number":_vm.evaluationObject.doctorSpecialization?.doctor?.phoneNumber,"diagnosis-title":"ЛПУ","diagnosis":_vm.evaluationObject?.doctorClinics[0]?.title}})],1),(_vm.reportObject)?_c('EvaluationsReportTable',{attrs:{"report-object":_vm.reportObject.lastMeasurements}}):_vm._e(),_c('EvaluationsModalComment',{attrs:{"title":"Комментарий колл-центра","is-call-center":"","comment-call-center":_vm.evaluationObject.review}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }