<template>
  <div class="base-page-layout w-100 pt-4 pb-4 overflow-auto">
    <b-container fluid="xl">
      <div class="base-page-layout_head h3 mb-3 d-flex justify-between">
        <span>{{ title }}</span>
        <slot name="head" />
      </div>
      <slot />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'BasePageLayout',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
