export default function fullNameFio(lastName, firstName, middleName) {
  return [
    lastName,
    firstName,
    middleName,
  ]
    .filter((name) => name && name.trim() !== '')
    .join(' ')
    .trim();
}
