<template>
  <div class="check-list-buttons">
    <VButton
      v-if="isAllDone"
      label="Скачать отчёт"
      size="m"
      :left-icon="{ name: 'download', size: 's' }"
      class="check-list-buttons_button"
      @click="$emit('downloadReport')"
    />
    <template v-else>
      <VButton
        label="Завершить оценку"
        size="m"
        :disabled="isDisabled"
        class="check-list-buttons_button"
        @click="$emit('checkListDone')"
      />
      <VButton
        label="Сохранить"
        size="m"
        :disabled="isSavingDisabled"
        variant="another1"
        class="check-list-buttons_button"
        @click="$emit('checkListSave')"
      />
    </template>
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem';

export default {
  name: 'EvaluationsCheckListButtons',
  components: {
    VButton,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: true,
    },
    isSavingDisabled: {
      type: Boolean,
      default: true,
    },
    isComment: {
      type: Boolean,
      default: false,
    },
    isAllDone: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .check-list-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    &_button {
      color: #ffffff;
    }
  }
</style>
