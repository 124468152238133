<template>
  <div class="base-page-no-result">
    <component :is="iconComponent" />
    <p class="mb-0">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'BasePageNoResult',
  props: {
    icon: {
      type: String,
      default: 'basePage/baloon-icon.svg',
    },
    text: {
      type: String,
      default: 'Пока нет ни одного ответа',
    },
  },
  computed: {
    iconComponent() {
      // Динамический импорт иконки
      return () => import(`@/assets/images/${this.icon}`);
    },
  },
};
</script>

<style lang="scss">
  .base-page-no-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 170px;
    font-weight: 500;
    color: #161E2F;
    p {
      margin-bottom: 0;
    }
  }
</style>
