import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function downloadProtocolPdf(protocol) {
  // Проверяем наличие протокола
  if (!protocol) return;

  // Извлекаем fullName из каждого элемента mkB10s, если он есть
  const mkB10sFullNames = protocol.mkB10s
    ? protocol.mkB10s.map((item) => item.fullName).filter((name) => name) // фильтруем null и undefined
    : [];

  // Определяем структуру документа
  const docDefinition = {
    content: [
      {
        text: 'Протокол консультации',
        style: 'header',
      },
      // Анамнез
      ...(protocol.anamnesis
        ? [
          {
            text: 'Анамнес:',
            style: 'subheader',
          },
          {
            text: protocol.anamnesis,
            style: 'paragraph',
          },
        ]
        : []),
      // Рекомендации
      ...(protocol.recomendation
        ? [
          {
            text: 'Рекомендации:',
            style: 'subheader',
          },
          {
            text: protocol.recomendation,
            style: 'paragraph',
          },
        ]
        : []),
      // Коды МКБ-10
      ...(mkB10sFullNames.length > 0
        ? [
          {
            text: 'Коды МКБ-10:',
            style: 'subheader',
          },
          {
            text: mkB10sFullNames.join(', '),
            style: 'paragraph',
          },
        ]
        : []),
    ],
    // Стили документа
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 20],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      paragraph: {
        fontSize: 12,
        margin: [0, 0, 0, 10],
      },
    },
    // Настройки страницы
    pageSize: 'A4',
    pageMargins: [40, 60, 40, 60],
  };

  // Создаем PDF
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  // Скачиваем файл
  pdfDocGenerator.download(`protocol_${Date.now()}.pdf`);
}
