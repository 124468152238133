<template>
  <BasePageLayout class="evaluations-page" title="Оценка консультаций">
    <EvaluationsSearchPanel
      :select-options="selectOptions"
      :select-active="selectActive"
      :search-placeholder="selectActive?.placeholder"
      :search-type="selectActive?.type"
      :radio-options="radioOptions"
      @querySearch="querySearch"
      @selectedOption="selectedOption"
    />
    <Preloader class="mt-5" v-if="isLoading" />
    <AsyncList
      v-if="isAsyncList"
      :is-all-items-loaded="isAllItemsLoaded"
      @load-more-items="loadMoreItems"
      class="evaluations-page_async-list mt-3"
    >
      <div class="d-flex flex-column gap-4">
        <EvaluationsCard
          v-for="item in evaluationsList"
          :key="item.id"
          :evaluation="item"
          @openModal="openModal"
        />
      </div>
    </AsyncList>
    <BasePageNoResult v-if="isNoResult" />
    <EvaluationsCardModal
      v-if="isModalOpen"
      :evaluation-object="selectedEvaluationObject"
      @closeModal="closeModal"
    />
  </BasePageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsyncList from '@/components/AsyncList.vue';
import Preloader from '@/components/Preloader.vue';
import {
  FETCH_EVALUATIONS_LIST,
  GET_EVALUATIONS_LIST,
} from '@/store/types';
import {
  BasePageLayout,
  BasePageNoResult,
} from '@/components/base/Page/index';
import {
  EvaluationsSearchPanel,
  EvaluationsCard,
  EvaluationsCardModal,
} from '@/components/Consultations/Evaluations/index';

export default {
  name: 'Evaluations',
  page: {
    title: 'CRM Doctis - Оценка консультаций',
  },
  components: {
    Preloader,
    BasePageLayout,
    EvaluationsSearchPanel,
    AsyncList,
    EvaluationsCard,
    BasePageNoResult,
    EvaluationsCardModal,
  },
  data() {
    return {
      isModalOpen: false,
      selectedEvaluationObject: null,
      isAllItemsLoaded: false,
      isLoading: true,
      page: 0,
      searchQuery: null,
      selectedRadioValue: null,
      selectedEvaluationId: null,
      selectOptions: [
        {
          id: 'searchStringPatient',
          name: 'ФИО врача или пациента',
          placeholder: 'ФИО пациента, врача или законного представителя',
          type: 'text',
        },
        {
          id: 'consultationId',
          name: 'Id консультации',
          placeholder: 'Id консультации',
          type: 'number',
        },
      ],
      // если дм сделает что можно фильтровать по нескольким тегам
      // checkboxOptions: [
      //   {
      //     value: 'ВТБ',
      //     label: 'Проект ВТБ',
      //   },
      //   {
      //     value: 'КТГ',
      //     label: 'Расшифровка КТГ',
      //   },
      // ],
      radioOptions: [
        { value: '', label: 'Не выбрано' },
        { value: 'ВТБ', label: 'Проект ВТБ' },
        { value: 'КТГ', label: 'Расшифровка КТГ' },
      ],
      selectActive: {
        id: 'searchStringPatient',
        name: 'ФИО врача или пациента',
        placeholder: 'ФИО пациента, врача или законного представителя',
      },
    };
  },
  computed: {
    ...mapGetters({
      evaluationsList: GET_EVALUATIONS_LIST,
    }),
    isAsyncList() {
      return this.evaluationsList.length && !this.isLoading;
    },
    isNoResult() {
      return !this.evaluationsList.length && !this.isLoading;
    },
    // selectedEvaluationObject() {
    //   const id = Number(this.$route.query.id);
    //   return this.evaluationsList.find((item) => item.id === id);
    // },
  },
  async mounted() {
    await this.loadMoreItems();
    if (!this.evaluationsList.length) {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchEvaluationsList: FETCH_EVALUATIONS_LIST,
    }),
    prepareParams(query) {
      return {
        query: this.searchQuery || query,
        take: 10,
        skip: this.page * 10,
        status: 2,
        monitoringTag: this.selectedRadioValue,
        [this.selectActive.id]: query,
      };
    },
    async loadMoreItems(query, tags) {
      if (this.isAllItemsLoaded) return;
      try {
        const searchQuery = query || this.searchQuery;
        const params = this.prepareParams(searchQuery, tags);
        const result = await this.fetchEvaluationsList(params);

        if (result.length < 10) {
          this.isAllItemsLoaded = true;
        }

        this.page += 1;
      } catch (error) {
        console.error('Ошибка загрузки:', error);
        this.isAllItemsLoaded = true;
      } finally {
        this.isLoading = false;
      }
    },
    async querySearch(query, tag) {
      this.page = 0;
      this.isLoading = true;
      this.isAllItemsLoaded = false;
      this.searchQuery = query;
      this.selectedRadioValue = tag; // tag.join(',') если фильтр по checkbox
      await this.loadMoreItems(query, tag);
    },
    selectedOption(option) {
      this.selectActive = { ...option };
    },
    closeModal() {
      this.isModalOpen = false;
      this.selectedEvaluationObject = null;
    },
    openModal(evaluation) {
      console.log(evaluation);
      this.selectedEvaluationObject = evaluation;
      this.isModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
  .evaluations-page {
    &_async-list {
      .__vuescroll {
        .__panel {
          overflow: visible !important;
          margin-right: 0 !important;
        }
        .__rail-is-vertical {
          display: none;
        }
      }
    }
  }
</style>
