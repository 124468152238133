<template>
  <div class="evaluations-search-panel">
    <div class="evaluations-search-panel_top">
      <BaseSelect
        :options="selectOptions"
        :value="selectActive"
        @change="handleChange"
      />
      <BaseSearch
        :placeholder="searchPlaceholder"
        :search-type="searchType"
        @search="handleSearch"
      />

    </div>
    <div class="evaluations-search-panel_bottom">
      <BaseRadioButton
        v-for="option in radioOptions"
        :key="option.value"
        v-model="radioValue"
        :name="option.value"
        class="evaluations-search-panel_radio"
      >
        {{ option.label }}
      </BaseRadioButton>
      <!-- TODO: если дм бека сделает фильтрацию по нескольким тегам, то добавить -->
      <!-- <BaseCheckbox
        v-for="option in checkboxOptions"
        :key="option.value"
        v-model="checkedOptions[option.value]"
        :label="option.value"
        class="evaluations-search-panel_radio"
        >
        {{ option.label }}
      </BaseCheckbox> -->
    </div>
  </div>
</template>

<script>
import {
  BaseSelect,
  BaseSearch,
  BaseRadioButton,
  // BaseCheckbox,
} from '@/components/base';

export default {
  name: 'EvaluationsSearchPanel',
  components: {
    BaseSelect,
    BaseSearch,
    BaseRadioButton,
    // BaseCheckbox,
  },
  props: {
    selectOptions: {
      type: Array,
      required: true,
    },
    radioOptions: {
      type: Array,
      required: true,
    },
    selectActive: {
      type: Object,
      default: () => {},
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      radioValue: null,
      // checkedOptions: {},
    };
  },
  // TODO: если дм бека сделает фильтрацию по нескольким тегам, то добавить
  // computed: {
  //   selectedCheckboxes() {
  //     return Object.keys(this.checkedOptions).filter((key) => this.checkedOptions[key]);
  //   },
  // },
  methods: {
    handleChange(selectedOption) {
      this.$emit('selectedOption', selectedOption);
    },
    handleSearch(query) {
      this.$emit('querySearch', query, this.radioValue);
    },
  },
};
</script>

<style lang="scss">
  .evaluations-search-panel {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &_top {
      display: grid;
      grid-template-columns: 234px 1fr;
      gap: 8px;
    }
    &_bottom {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &_radio {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0px 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      color: #161E2F;
    }
  }
</style>
