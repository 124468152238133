<template>
  <button
    class="evaluations-card"
    @click="openModal"
  >
    <EvaluationsCardBlock
      title="Пациент"
      attr-right-icon="copy"
      :copy-content="String(evaluation.patientId)"
      :fio="fullNameFio(
        evaluation.patient?.lastName,
        evaluation.patient?.firstName,
        evaluation.patient?.middleName)"
      :phone-number="evaluation.patient?.phoneNumber"
    />
    <EvaluationsCardBlock
      title="Врач"
      attr-left-icon="doctor"
      :attr-content="doctorSpecialization"
      :fio="fullNameFio(
        evaluation.doctorSpecialization?.doctor?.lastName,
        evaluation.doctorSpecialization?.doctor?.firstName,
        evaluation.doctorSpecialization?.doctor?.middleName
      )"
      :phone-number="evaluation.doctorSpecialization?.doctor?.phoneNumber"
    />
    <EvaluationsCardBlock
      title="Консультация"
      attr-right-icon="copy"
      :copy-content="String(evaluation?.id)"
      :status-text="evaluation?.statusText"
      :status-text-variant="attrVariant(evaluation?.status + 1)?.variant"
      :time-start="formatConsultationDate(evaluation?.createDate)"
      :time-end="formatConsultationDate(evaluation?.takeOutDate)"
      :points-number="evaluation?.evaluationScore"
    />
    <EvaluatonsCardStatus
      :status="evaluation?.evalStatus"
      :attr-variant="attrVariant(evaluation?.evalStatus + 1)?.variant"
      :attr-icon="attrVariant(evaluation?.evalStatus + 1)?.icon"
      :protocol="evaluation.protocol"
      class="evaluations-card_status"
    />
  </button>
</template>

<script>
import { formatConsultationDate, fullNameFio } from '@/helpers/index.js';
import { EvaluationsCardBlock, EvaluatonsCardStatus } from './Card/index.js';

export default {
  name: 'EvaluationsCard',
  components: {
    EvaluationsCardBlock,
    EvaluatonsCardStatus,
  },
  props: {
    evaluation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusses: {
        new: {
          icon: 'alert',
          variant: 'alarm',
        },
        inprogress: {
          icon: 'clock',
          variant: 'warning',
        },
        done: {
          icon: 'check',
          variant: 'success',
        },
      },
    };
  },
  computed: {
    doctorSpecialization() {
      return this.evaluation?.doctorSpecialization?.specialization.title || '—';
    },
  },
  methods: {
    fullNameFio,
    formatConsultationDate,
    attrVariant(element) {
      switch (element) {
        case 2: return this.statusses.inprogress;
        case 3: return this.statusses.done;
        default: return this.statusses.new;
      }
    },
    openModal() {
      this.$emit('openModal', this.evaluation);
    },
  },
};
</script>

<style lang="scss">
  .evaluations-card {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    display: grid;
    grid-template-columns: 264px 264px 1fr 48px;
    align-items: stretch;
    transition: linear 0.2s;
    gap: 16px;
    &:hover {
      box-shadow: inset 0 0 4px rgba(64, 113, 255, 1);
    }
    p {
      margin-bottom: 0;
    }
    & > *:not(:last-child) {
      border-right: 1px solid #E8E8EA;
      padding-right: 16px;
    }
    &_status {
      margin-left: auto;
    }
  }
</style>
