import { showMessage } from '@/helpers/utils';

const copyToClipboard = (content) => {
  navigator.clipboard.writeText(content)
    .then(() => {
      showMessage({
        type: 'success',
        title: 'ID скопирован в буфер обмена',
      });
    })
    .catch((err) => {
      console.error('Не удалось скопировать: ', err);
      showMessage({
        type: 'danger',
        title: 'Не удалось скопировать',
      });
    });
};

export default copyToClipboard;
