<template>
  <div>
    <EvaluationsMonitoringBlock
      v-if="reportObject"
      :monitoring-object="reportObject"
      :protocol-tag-title="evaluationObject.protocol?.protocolTag?.title"
    />
    <div class="d-flex gap-4">
      <EvaluationsCardBlock
        title="Пациент"
        attr-right-icon="copy"
        is-block-row
        :copy-content="String(evaluationObject.patientId)"
        :fio="fullNameFio(
          evaluationObject.patient?.lastName,
          evaluationObject.patient?.firstName,
          evaluationObject.patient?.middleName)"
        :phone-number="evaluationObject.patient?.phoneNumber"
        diagnosis-title="Диагноз"
        :diagnosis="evaluationObject?.body"
      />
      <EvaluationsCardBlock
        title="Врач"
        attr-left-icon="doctor"
        is-block-row
        attr-right-icon="copy"
        :copy-content="String(evaluationObject.doctorSpecialization?.doctor?.id)"
        :attr-content="doctorSpecialization"
        :fio="fullNameFio(
          evaluationObject.doctorSpecialization?.doctor?.lastName,
          evaluationObject.doctorSpecialization?.doctor?.firstName,
          evaluationObject.doctorSpecialization?.doctor?.middleName
        )"
        :phone-number="evaluationObject.doctorSpecialization?.doctor?.phoneNumber"
        diagnosis-title="ЛПУ"
        :diagnosis="evaluationObject?.doctorClinics[0]?.title"
      />
    </div>
    <EvaluationsReportTable
      v-if="reportObject"
      :report-object="reportObject.lastMeasurements"
    />
    <EvaluationsModalComment
      title="Комментарий колл-центра"
      is-call-center
      :comment-call-center="evaluationObject.review"
    />
  </div>
</template>

<script>
import { EvaluationsCardBlock } from '@/components/Consultations/Evaluations/Card/index.js';
import { fullNameFio } from '@/helpers/index.js';
import { EvaluationsReportTable, EvaluationsMonitoringBlock, EvaluationsModalComment } from '../Parts/index.js';

export default {
  name: 'EvaluationsInfo',
  components: {
    EvaluationsCardBlock,
    EvaluationsMonitoringBlock,
    EvaluationsReportTable,
    EvaluationsModalComment,
  },
  props: {
    evaluationObject: {
      type: Object,
      required: true,
    },
    reportObject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    doctorSpecialization() {
      return this.evaluationObject?.doctorSpecialization?.specialization.title || '—';
    },
  },
  methods: {
    fullNameFio,
  },
};
</script>

<style lang="scss" scoped>

</style>
