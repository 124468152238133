<template>
  <div class="monitoring-block">
    <span class="monitoring-block_title">Мониторинг</span>
      <div class="monitoring-block_content">
        <div class="monitoring-block_content-top">
          <div class="block-label">
            Шаблон
          </div>
          <div class="body">
            <span class="body-title">{{ monitoringObject.name }}</span>
            <button
              @click.stop="copyToClipboard(monitoringObject.id)"
            >
              <VAttribute
                :content="monitoringObject.id"
                :right-icon="{name: 'copy', size: 's'}"
                size="xs"
              />
            </button>
            <VAttribute
              :content="attrVariant(monitoringObject.status).content"
              :variant="attrVariant(monitoringObject.status).variant"
              size="xs"
            />
          </div>
        </div>
        <div class="monitoring-block_content-bottom">
          <div class="block">
            <div v-if="monitoringObject.startDate" class="block-item">
              <div class="block-label">
                Начало
              </div>
              <VAttribute
                :content="formatConsultationDate(monitoringObject.startDate)"
                size="s"
              />
            </div>
            <div class="block-line" />
            <div v-if="monitoringObject.endDate" class="block-item">
              <div class="block-label">
                Окончание
              </div>
              <VAttribute
                :content="formatConsultationDate(monitoringObject.endDate)"
                size="s"
              />
            </div>
          </div>
          <div v-if="protocolTagTitle" class="block-item">
            <div class="block-label">
              Реакция врача
            </div>
            <VAttribute
              :content="protocolTagTitle"
              variant="warning"
              class="block-item_attr"
              size="m"
            />
          </div>
          <div class="block-item block-item--last">
            <div class="block-label">
              Комментарий лечащего врача при постановке пациента на ДН
            </div>
            <VAttribute
              :content="monitoringObject.monitoringComment ? monitoringObject.monitoringComment : 'Комментарий отсутствует'"
              class="block-item_attr"
              size="m"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { VAttribute } from '@doctis.front/doctis.designsystem';
import { copyToClipboard, formatConsultationDate } from '@/helpers/index.js';

export default {
  name: 'EvaluationsMonitoringBlock',
  props: {
    monitoringObject: {
      type: Object,
      default: () => ({}),
    },
    protocolTagTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      statusses: {
        active: {
          variant: 'success',
          content: 'Активен',
        },
        done: {
          variant: 'info',
          content: 'Завершен',
        },
        pause: {
          variant: 'warning',
          content: 'На паузе',
        },
        created: {
          variant: 'info_colored',
          content: 'Создан',
        },
      },
    };
  },
  components: {
    VAttribute,
  },
  methods: {
    copyToClipboard,
    formatConsultationDate,
    attrVariant(status) {
      if (status === 1) return this.statusses.done;
      if (status === 4) return this.statusses.pause;
      if (status === 5) return this.statusses.created;
      return this.statusses.active;
    },
  },
};
</script>

<style lang="scss" scoped>
  .monitoring-block {
    display: flex;
    flex-direction: column;
    &_title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #161E2F;
      margin-bottom: 16px;
    }
    .block-label {
      font-size: 12px;
      color: #7F838D;
      line-height: 1;
      margin-bottom: 4px;
    }
    &_content {
      padding: 16px;
      border: 1px solid #E8E8EA;
      border-radius: 8px;
      &-top {
        padding-bottom: 8px;
        border-bottom: 1px solid #E8E8EA;
        .body {
          display: flex;
          align-items: center;
          gap: 8px;
          &-title {
            font-weight: 16px;
            line-height: 16px;
            font-weight: 500;
            color: #161E2F;
          }
        }
      }
      &-bottom {
        padding-top: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        .block {
          display: flex;
          align-items: center;
          gap: 12px;
          &-item {
            &_attr {
              text-align: left;
              justify-content: left;
              padding-left: 10px !important;
            }
            &--last {
              flex: 1;
            }
          }
          &-line {
            width: 16px;
            margin-top: 16px;
            min-width: 16px;
            height: 1px;
            background-color: #000000;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 3px;
            &::before {
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              left: -3px;
              display: block;
              background-color: #000000;
              border-radius: 50%;
            }
            &::after {
              content: '';
              width: 6px;
              position: absolute;
              right: -3px;
              height: 6px;
              display: block;
              background-color: #000000;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
</style>
