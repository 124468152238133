<template>
  <div v-if="attrVariant || protocol" class="evaluations-status">
    <VAttribute
      v-if="attrVariant"
      content=""
      :variant="attrVariant"
      :left-icon="{name: attrIcon, size: 's'}"
      class="evaluations-status_attr"
    />
    <VButton
      v-if="protocol"
      type="light"
      class="evaluations-status_download"
      :left-icon="{name: 'download', size: 's'}"
      :stop="true"
      @click="downloadProtocol"
    />
  </div>
</template>

<script>
import { VAttribute, VButton } from '@doctis.front/doctis.designsystem';
import downloadProtocolPdf from './pdfMake';

export default {
  name: 'EvaluatonsCardStatus',
  components: {
    VAttribute,
    VButton,
  },
  props: {
    attrVariant: {
      type: String,
      default: null,
    },
    attrIcon: {
      type: String,
      default: null,
    },
    protocol: {
      type: Object,
      default: null,
    },
  },
  methods: {
    downloadProtocol() {
      if (!this.protocol) {
        console.warn('Не удалось скачать протокол');
        return;
      }
      downloadProtocolPdf(this.protocol);
    },
  },
};
</script>

<style lang="scss">
  .evaluations-status {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &_attr {
      gap: 0;
    }
    &_download {
      svg {
        pointer-events: none;
      }
    }
  }
</style>
