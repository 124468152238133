const pointsNumberVariant = (element) => {
  if (element > 75) {
    return 'success';
  }
  if (element > 50) {
    return 'warning';
  }
  if (element < 0) {
    return 'alarm';
  }
  return 'info';
};
export default pointsNumberVariant;
