<template>
  <div class="check-list-info">
    <span class="check-list-info_title">{{ title }}</span>
    <div class="check-list-info_content" v-if="doctorInfo.length">
      <template v-for="(item, index) in doctorInfo">
        <div :key="index" v-if="item.content !== null && item.content !== ''">
          <div class="check-list-info_label">{{ item.label }}</div>
          <VAttribute
            class="check-list-info_attr"
            :variant="pointsNumberVariant(item.content)"
            size="s"
            :content="item.content"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { VAttribute } from '@doctis.front/doctis.designsystem';
import pointsNumberVariant from '@/components/Consultations/Evaluations/helpers/pointsNumberVariant';

export default {
  name: 'EvaluationsCheckListInfo',
  props: {
    title: {
      type: String,
      default: '',
    },
    doctorInfo: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {
    VAttribute,
  },
  methods: {
    pointsNumberVariant,
  },
};
</script>

<style lang="scss" scoped>
  .check-list-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #161E2F;
    &_title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
    &_label {
      color: #7F838D;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 4px;
    }
    &_content {
      display: flex;
      gap: 8px;
    }
  }
</style>
