<template>
  <div class="evaluations-block">
    <div class="evaluations-block_head">
      <span v-if="title" class="title">{{ title }}</span>
      <VAttribute
        v-if="attrLeftIcon"
        :variant="attrVariant"
        :content="attrContent"
        class="evaluations-block_head-attr"
        :left-icon="{name: `${attrLeftIcon}`, size: 's'}"
        size="xs"
      />
      <Button
        v-if="copyContent"
        @click.stop="copyToClipboard(copyContent)"
      >
        <VAttribute
          :variant="attrVariant"
          :content="`id ${copyContent}`"
          :right-icon="{name: `${attrRightIcon}`, size: 's'}"
          size="xs"
        />
      </Button>
      <VAttribute
        v-if="statusText"
        :variant="statusTextVariant"
        :content="statusText"
        size="xs"
      />
    </div>
    <div
      v-if="fio"
      :class="[
        'evaluations-block_info',
        { 'evaluations-block_info--row': isBlockRow },
      ]"
    >
      <div class="evaluations-block_item">
        <p class="title">
          ФИО
        </p>
        <p>{{ fio }}</p>
      </div>
      <div v-if="phoneNumber" class="evaluations-block_item">
        <p class="title">
          Телефон
        </p>
        <p>{{ phoneNumber }}</p>
      </div>
      <div v-if="diagnosis" class="evaluations-block_item evaluations-block_item--bottom">
        <p class="title">
          {{ diagnosisTitle }}
        </p>
        <VAttribute
          :content="diagnosis"
          class="evaluations-block_item-attr"
          size="m"
        />
      </div>
    </div>
    <div v-if="timeStart || timeEnd" class="evaluations-block_consultation">
      <div class="evaluations-block_item">
        <p class="title">
          Создана
        </p>
        <VAttribute
          :variant="timeStartVariant"
          :content="timeStart"
          class="evaluations-block_item-attr"
          size="s"
        />
      </div>
      <div v-if="timeEnd" class="evaluations-block_item">
        <p class="title">
          Взята в работу
        </p>
        <VAttribute
          :variant="timeEndVariant"
          :content="timeEnd"
          class="evaluations-block_item-attr"
          size="s"
        />
      </div>
    </div>
    <div v-if="pointsNumber" class="evaluations-block_item">
      <p class="title">
        Итого баллов
      </p>
      <VAttribute
        :variant="pointsNumberVariant(pointsNumber)"
        :content="String(pointsNumber)"
        class="evaluations-block_item-points-attr"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import { VAttribute } from '@doctis.front/doctis.designsystem';
import copyToClipboard from '@/helpers/copyToClipboard';
import pointsNumberVariant from '@/components/Consultations/Evaluations/helpers/pointsNumberVariant';

export default {
  name: 'EvaluationsCardBlock',
  components: {
    VAttribute,
  },
  props: {
    isBlockRow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    attrVariant: {
      type: String,
      default: 'info',
    },
    attrContent: {
      type: String,
      default: '',
    },
    copyContent: {
      type: String,
      default: '',
    },
    attrRightIcon: {
      type: String,
      default: '',
    },
    attrLeftIcon: {
      type: String,
      default: '',
    },
    fio: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    diagnosisTitle: {
      type: String,
      default: '',
    },
    diagnosis: {
      type: String,
      default: '',
    },
    statusText: {
      type: String,
      default: '',
    },
    statusTextVariant: {
      type: String,
      default: 'success',
    },
    timeStart: {
      type: String,
      default: '',
    },
    timeStartVariant: {
      type: String,
      default: 'info',
    },
    timeEnd: {
      type: String,
      default: '',
    },
    timeEndVariant: {
      type: String,
      default: 'info',
    },
    pointsNumber: {
      type: Number,
      default: null,
    },
  },
  methods: {
    pointsNumberVariant,
    copyToClipboard,
  },
};
</script>

<style lang="scss">
  .evaluations-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #161E2F;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    .title {
      font-size: 12px;
      line-height: 1;
      color: #7F838D;
      font-weight: normal;
    }
    &_head {
      display: flex;
      align-items: center;
      gap: 8px;
      .title {
        color: #161E2F;
        font-weight: 500;
        font-size: 16px;
      }
      &-attr {
        span {
          display: block;
        }
      }
    }
    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      gap: 4px;
      &--bottom {
        width: 100%;
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid #E8E8EA;
      }
      &-attr {
        min-width: 103px;
        min-height: fit-content;
        padding-top: 10px;
        padding-bottom: 10px;
        span {
          display: block;
          white-space: normal;
          line-height: 1.2;
        }
      }
      &-points-attr {
        min-width: 79px;
      }
    }
    &_info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;
      &--row {
        flex-direction: row;
        flex-wrap: wrap;
        border-radius: 8px;
        border: 1px solid #E8E8EA;
        padding: 16px;
        max-width: 100%;
        justify-content: space-between;
      }
    }
    &_consultation {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
</style>
