var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"evaluations-card",on:{"click":_vm.openModal}},[_c('EvaluationsCardBlock',{attrs:{"title":"Пациент","attr-right-icon":"copy","copy-content":String(_vm.evaluation.patientId),"fio":_vm.fullNameFio(
      _vm.evaluation.patient?.lastName,
      _vm.evaluation.patient?.firstName,
      _vm.evaluation.patient?.middleName),"phone-number":_vm.evaluation.patient?.phoneNumber}}),_c('EvaluationsCardBlock',{attrs:{"title":"Врач","attr-left-icon":"doctor","attr-content":_vm.doctorSpecialization,"fio":_vm.fullNameFio(
      _vm.evaluation.doctorSpecialization?.doctor?.lastName,
      _vm.evaluation.doctorSpecialization?.doctor?.firstName,
      _vm.evaluation.doctorSpecialization?.doctor?.middleName
    ),"phone-number":_vm.evaluation.doctorSpecialization?.doctor?.phoneNumber}}),_c('EvaluationsCardBlock',{attrs:{"title":"Консультация","attr-right-icon":"copy","copy-content":String(_vm.evaluation?.id),"status-text":_vm.evaluation?.statusText,"status-text-variant":_vm.attrVariant(_vm.evaluation?.status + 1)?.variant,"time-start":_vm.formatConsultationDate(_vm.evaluation?.createDate),"time-end":_vm.formatConsultationDate(_vm.evaluation?.takeOutDate),"points-number":_vm.evaluation?.evaluationScore}}),_c('EvaluatonsCardStatus',{staticClass:"evaluations-card_status",attrs:{"status":_vm.evaluation?.evalStatus,"attr-variant":_vm.attrVariant(_vm.evaluation?.evalStatus + 1)?.variant,"attr-icon":_vm.attrVariant(_vm.evaluation?.evalStatus + 1)?.icon,"protocol":_vm.evaluation.protocol}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }