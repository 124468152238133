<template>
  <div class="check-list-table">
    <div v-if="checkListObject">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col">Название</th>
            <th scope="col">Комментарий</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in checkListObject"
            :key="row.id"
          >
            <td>
              <div class="table_radio-group">
                <label v-for="radio in radioValue" :key="row.id + radio.value">
                  <input
                    type="radio"
                    :name="row.id"
                    :disabled="isDisabled"
                    :value="radio.value"
                    :checked="row.value === radio.value"
                    @change="updateCheckedList(row, radio.value)"
                  >
                  <span>{{ radio.label }}</span>
                </label>
              </div>
            </td>
            <td>
              {{ row.title}}
            </td>
            <td>
              {{ row.description }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvaluationsCheckListTable',
  props: {
    checkListObject: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioValue: [
        {
          label: 'Да',
          value: true,
        },
        {
          label: 'Нет',
          value: false,
        },
      ],
      checkedList: [],
    };
  },
  methods: {
    updateCheckedList(row, value) {
      // Найти существующий элемент или создать новый
      const existingItemIndex = this.checkedList.findIndex((item) => item.id === row.id);
      if (existingItemIndex !== -1) {
        // Обновить существующий элемент
        this.checkedList[existingItemIndex].value = value;
      } else {
        // Добавить новый элемент
        this.checkedList.push({
          evaluation_checkbox_id: row.id,
          value,
        });
      }
      this.$emit('update:checkedList', this.checkedList);
    },
  },
};
</script>

<style lang="scss" scoped>
  .check-list-table {
    .table {
      height: 340px;
      display: block;
      overflow: auto;
      border: 1px solid #E8E8EA;
      border-radius: 8px;
      border-collapse: collapse;
      font-size: 16px;
      td, th {
        &:not(:last-child) {
          border-right: 1px solid #E8E8EA;
        }
      }
      td {
        line-height: 1.2;
        color: #212529;
      }
      &_radio-group {
        display: flex;
        align-items: center;
        gap: 24px;
        label {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
        }
      }
    }
  }
</style>
