<template>
  <div class="report-table">
    <span class="report-table_title">Отчёт по последним событиям</span>
    <table class="report-table_table table">
      <thead>
        <tr>
          <th scope="col">Плановое время передачи</th>
          <th scope="col">Фактическое время передачи</th>
          <th scope="col">Тип передачи показателей</th>
          <th scope="col">Статус передачи показателей</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in reportObject"
          :key="row.id"
        >
          <td>{{ row.isEmergency ? '—' : plannedDate(row.plannedDate?.startDate, row.plannedDate?.endDate) }}</td>
          <td>{{ row.measurementDate ? formatConsultationDate(row.measurementDate) : '—' }}</td>
          <td>
            <VAttribute
              class="report-table_table-attribute"
              :content="dataTransferType(row.isEmergency)"
              size="s"
            />
          </td>
          <td>
            <VAttribute
              class="report-table_table-attribute"
              :variant="attrVariant(row.status).variant"
              :content="attrVariant(row.status).content"
              size="s"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatConsultationDate } from '@/helpers/index.js';
import { VAttribute } from '@doctis.front/doctis.designsystem';

export default {
  name: 'EvaluationsReportTable',
  components: {
    VAttribute,
  },
  props: {
    reportObject: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusses: {
        new: {
          content: 'Запланировано',
          variant: 'info',
        },
        error: {
          content: 'Данные не получены',
          variant: 'alarm',
        },
        success: {
          content: 'Успешно получены',
          variant: 'success',
        },
      },
    };
  },
  methods: {
    formatConsultationDate,
    dataTransferType(isEmergency) {
      return isEmergency ? 'Внепланово' : 'Планово';
    },
    attrVariant(status) {
      if (status === 2) return this.statusses.success;
      if (status === 3) return this.statusses.error;
      return this.statusses.new;
    },
    plannedDate(start, end) {
      const date = this.formatConsultationDate(start, 'd MMM');
      const startTime = this.formatConsultationDate(start, 'HH:mm');
      const endTime = this.formatConsultationDate(end, 'HH:mm');
      return `${date} ${startTime} — ${endTime}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .report-table {
    &_title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      display: block;
    }
    &_table {
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;
      thead {
        font-size: 16px;
        font-weight: 500;
        color: #161E2F;
        background-color: #E8E8EA;
      }
      td {
        font-size: 16px;
        line-height: 16px;
        color: #161E2F;
        vertical-align: middle;
      }
      &-attribute {
        width: fit-content;
      }
    }
  }
</style>
